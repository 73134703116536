/* eslint-disable max-len */
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {
  ReactElement,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleActiveAudio } from 'reducers/trainInfos'
import { RootState } from 'reducers/store'

const MaterialUISwitch = styled(Switch)(() => ({
  width: 70,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M7.58 4.08 6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"/></svg>')`,

      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'white',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'green',
        '&:before': {
          left: -1,
          top: -2,
        },
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'red',
    width: 34,
    height: 34,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: -2,
      top: -2,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M20 18.69 7.84 6.14 5.27 3.49 4 4.76l2.8 2.8v.01c-.52.99-.8 2.16-.8 3.42v5l-2 2v1h13.73l2 2L21 19.72l-1-1.03zM12 22c1.11 0 2-.89 2-2h-4c0 1.11.89 2 2 2zm6-7.32V11c0-3.08-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.15.03-.29.08-.42.12-.1.03-.2.07-.3.11h-.01c-.01 0-.01 0-.02.01-.23.09-.46.2-.68.31 0 0-.01 0-.01.01L18 14.68z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'white',
    borderRadius: 20 / 2,
  },
}))

export const AudioToggle = (): ReactElement => {
  const dispatch = useDispatch()
  const { activeAudio } = useSelector((state: RootState) => state.trainInfos)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleActiveAudio(event.target.checked))
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <MaterialUISwitch
            onChange={handleChange}
            checked={activeAudio}
          />
)}
        label=""
      />
    </FormGroup>
  )
}

export default AudioToggle
