import { ReactElement } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import './Countdown.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers/store'
import { deactivateCountdown } from 'reducers/trainInfos'
import { ReactComponent as Close } from 'assets/icons/close.svg'

const Countdown = (): ReactElement => {
  const dispatch = useDispatch()
  const { activeCountdown } = useSelector((state: RootState) => state.trainInfos)

  const renderTime = (remainingTime: number): ReactElement => (
    <div className={`timer${remainingTime <= 30 ? ' late' : ''}`}>
      {`${
        remainingTime / 60 < 10 ? '0' : ''
      }${Math.floor(remainingTime / 60)}:${
        remainingTime % 60 < 10 ? '0' : ''
      }${remainingTime % 60}`}
    </div>
  )

  const handleClose = () => {
    if (activeCountdown?.messageId) {
      dispatch(deactivateCountdown(activeCountdown.messageId))
    }
  }

  const handleCompleteCooldown = () => {
    setTimeout(handleClose, 5000)
  }

  return (
    <div className="countdown-container" style={{ background: '#1F2123', flex: 1 }}>
      {activeCountdown && (
        <>
          <button type="button" className="close" onClick={handleClose}>
            <Close />
          </button>
          <CountdownCircleTimer
            key={activeCountdown.messageId}
            duration={activeCountdown.duration}
            initialRemainingTime={activeCountdown.timeLeft}
            colors={['#79DB56', '#FFA500', '#FFA500']}
            colorsTime={[30, 30, 0]}
            isPlaying
            isGrowing
            trailStrokeWidth={2}
            strokeWidth={12}
            onComplete={handleCompleteCooldown}
            size={256}
          >
            {({ remainingTime }) => renderTime(remainingTime)}
          </CountdownCircleTimer>
        </>
      )}
    </div>
  )
}

export default Countdown
