import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import ThemisServices from 'services/ThemisServices'
import { TrainInfosState, TrainInfoMessage, ChronoMessage } from './types'

const initialState: TrainInfosState = {
  messages: [],
  activeCountdown: null,
  clockSkew: 0,
  activeAudio: false,
  status: {
    observations: false,
    theoricalTrains: false,
  },
}

export const trainInfosSlice = createSlice({
  name: 'trainInfosSlice',
  initialState,
  reducers: {
    updateMessages: (state, action: PayloadAction<TrainInfoMessage[]>) => {
      state.messages = action.payload
      const newChrono = action.payload.find(m => m.type_message === 'annonce' && m.chrono) as ChronoMessage | undefined

      if (newChrono?.chrono && newChrono?.chrono_initial && newChrono.chrono > 0) {
        state.activeCountdown = {
          messageId: newChrono.id,
          duration: newChrono.chrono_initial,
          timeLeft: newChrono.chrono,
        }
      } else {
        state.activeCountdown = null
      }
    },
    deactivateCountdown: (state, action: PayloadAction<string>) => {
      if (state.activeCountdown?.messageId === action.payload) {
        state.activeCountdown = null
      }
    },
    resetMessages: state => {
      state.messages = []
      state.activeCountdown = null
    },
    toggleActiveAudio: (state, action: PayloadAction<boolean>) => {
      state.activeAudio = action.payload
    },
    setStatus: (state, action: PayloadAction<{observations: boolean, theoricalTrains: boolean}>) => {
      state.status = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ThemisServices.getTime.fulfilled, (state, { payload }) => {
      const nowTimeStamp = (new Date()).getTime()

      const serverTimestamp = payload[0].server_time * 1000.0
      const clientTimestamp = payload[1]

      const clockSkew = ((serverTimestamp - clientTimestamp) + (serverTimestamp - nowTimeStamp)) / 2.0

      state.clockSkew = clockSkew
    })
  },
})

export const {
  deactivateCountdown, updateMessages, resetMessages, toggleActiveAudio, setStatus,
} = trainInfosSlice.actions

export default trainInfosSlice.reducer
