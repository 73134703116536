import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ThemisServices from 'services/ThemisServices'
import Informations from 'components/Informations/Informations'
import Countdown from 'components/Countdown/Countdown'
import { RootState } from 'reducers/store'
import './Home.scss'
import MessageStream from 'components/MessageStream/MessageStream'

const Home = (): ReactElement => {
  const dispatch = useDispatch()
  const { messages } = useSelector((state: RootState) => state.trainInfos)

  useEffect(() => {
    const ct = (new Date()).getTime()
    dispatch(ThemisServices.getTime(ct))
  }, [])

  return (
    <div id="homepage">
      <Informations messages={messages} />
      <Countdown />
      <MessageStream />
    </div>
  )
}

export default Home
