import terms from 'common/terms'
import { ReactElement, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/store'
import { TrainInfoMessage } from 'reducers/types'
import './Informations.scss'
import nextId from 'react-id-generator'
import gong from 'assets/sounds/gong.mp3'

interface Props {
  messages: TrainInfoMessage[]
}

const Informations = ({ messages }: Props): ReactElement => {
  const [audio] = useState(new Audio(gong))
  const { activeCountdown, activeAudio } = useSelector((state: RootState) => state.trainInfos)

  useEffect(() => {
    if (activeCountdown
      && (Math.trunc(activeCountdown.duration) === (Math.trunc(activeCountdown.timeLeft)))
      && activeAudio) {
      audio.play()
    }
  },
  [activeCountdown])

  return (
    <div className="informations-container">
      <div className="title">
        {terms.Informations.title}
      </div>
      <div className="list">
        {messages.map(message => (
          <div className="message" key={nextId()}>
            <div className="timestamp">
              {message.heure_obs}
            </div>
            {message.type_message === 'annonce' && (
            <div className="content-annonce">
              <span>
                {`${message.message} : `}
              </span>
              <strong>
                {message.action}
              </strong>
              {message.message_avertissement && (
                <>
                  <br />
                  <strong className="warning">
                    {message.message_avertissement}
                  </strong>
                </>
              )}
            </div>
            )}
            {message.type_message === 'train' && (
            <div className={`content-train ${message.retard > 0 && 'delayed'} ${message.plain_text ? 'plain' : ''}`}>
              <div className="infos">
                <span className="number">
                  {message.numero_marche}
                </span>
                {message.retard <= 0 ? (
                  <div className="delay on-time">
                    {message.retard === 0 ? terms.Informations.onTime
                      : `${terms.Informations.early} ${-message.retard}${terms.Informations.minute}`}
                  </div>
                ) : (
                  <div className="delay">
                    {`${terms.Informations.delay} ${message.retard}${terms.Informations.minute}`}
                  </div>
                )}
              </div>
              <span className="location">
                {message.localisation}
              </span>
            </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Informations
