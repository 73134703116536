/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
  },
  Informations: {
    title: 'Informations en temps réel',
    onTime: 'À l\'heure',
    delay: 'Retard de',
    early: 'Avance de',
    minute: 'min',
    startChrono: 'Démarrage d\'un chrono de ',
    seconds: 'secondes',
  },
  StatusStream: {
    observations: 'Observations',
    theoricalTrains: 'Circulations théoriques',
  },
} as const

export default terms
